import React, { Component } from "react";
import Dialog from "../../../src/Components/Dialog";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../../src/ActionCreator";
import Selectors from "../../../src/Selectors";
import * as Unstated from "../../../src/Utils/Unstated";
import * as Widget from "../../../src/Components/Widget";
import * as Widget2 from "../../../plugin/Components/Widget2";
import * as Icon from "../../../src/Components/Icon";
import * as L from "../../../src/Utils/Lang";

const Wrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  background-color: #ffffff;
  width: 560px;
`;

const UiState = {
  LOGIN: "LOGIN",
  RESET_PASSWORD: "RESET_PASSWORD",
  VALIDATE_ACCOUNT: "VALIDATE_ACCOUNT"
};

class LoginPanel extends Component {
  state = {
    uiState: UiState.LOGIN,
    submitSuccess: false
  };

  render() {
    let { uiState } = this.state;

    switch (uiState) {
      case UiState.LOGIN:
        return this._renderLogin();
      case UiState.RESET_PASSWORD:
        return this._renderResetPassword();
      case UiState.VALIDATE_ACCOUNT:
        return this._renderValidateAccount();
      default:
        return null;
    }
  }

  _renderLogin = () => {
    let { onClose, appActions, navActions } = this.props;
    let { submitSuccess } = this.state;

    return (
      <Wrapper>
        <Unstated.Form
          key="login"
          init={() => ({
            values: { username: "", password: "" },
            editing: true
          })}
          submit={values => {
            if (!values.username || !values.password) {
              return Promise.resolve({
                values,
                error: L.s("all-fields-required")
              });
            }

            let { username, password } = values;
            return appActions
              .login({ username, password })
              .then(() => this.setState({ submitSuccess: true }))
              .then(() => navActions.push("/me"))
              .catch(err => {
                if (err.status === 401) {
                  return { error: L.s("error-login-message") };
                }
                if (err.status === 491) {
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password
                  });
                }
                return { error: L.s("error-message") };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <div style={{ padding: 15 }}>
              <div
                style={{
                  marginBottom: 30,
                  textAlign: "center",
                  fontSize: 16,
                  color: "#777777"
                }}
              >
                登入系統
              </div>
              <Widget.MaxWidth width="343">
                <Widget2.FormField
                  inputWidth={"300px"}
                  css="margin: 0px 0px 20px 0px; align-items: center;"
                >
                  <label style={{ marginRight: 15 }}>帳號</label>
                  <input {...inputProps.username} />
                </Widget2.FormField>

                <Widget2.FormField
                  inputWidth={"300px"}
                  css="margin: 0px 0px 30px 0px; align-items: center;"
                >
                  <label style={{ marginRight: 15 }}>密碼</label>
                  <input type="password" {...inputProps.password} />
                </Widget2.FormField>

                <div style={{ marginTop: 10 }}>
                  {submitProps.submitting && (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  )}

                  {!submitSuccess && (
                    <Widget.Button
                      label={L.s("login")}
                      labelStyle={{ color: "white" }}
                      onClick={submitProps.submit}
                      disabled={!submitProps.enabled || submitProps.submitting}
                      backgroundColor="#4485D1"
                      style={{ height: 40, width: "100%", marginBottom: 20 }}
                      buttonStyle={{ borderRadius: 0 }}
                    />
                  )}

                  {submitProps.submitError && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: 10
                      }}
                    >
                      {submitProps.submitError}
                    </div>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      color: "#4485D1",
                      fontSize: 14,
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.setState({ uiState: UiState.RESET_PASSWORD })
                    }
                  >
                    {L.s("forget-password")}
                  </div>
                </div>
              </Widget.MaxWidth>
            </div>
          )}
        </Unstated.Form>
      </Wrapper>
    );
  };

  _renderResetPassword = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <div style={{ padding: 20 }}>
          <h2
            style={{
              marginBottom: 10,
              textAlign: "center",
              borderBottom: "1px solid lightgrey",
              color: "#444"
            }}
          >
            RESET PASSWORD
          </h2>

          <Unstated.WithState state={{ resetPasswordSuccess: false }}>
            {({ state, setState }) => {
              let { resetPasswordSuccess } = state;

              if (resetPasswordSuccess === true) {
                return (
                  <Widget.MaxWidth width="400">
                    <div>{L.s("reset-password-success-message")}</div>

                    <Widget.Center
                      extraCss={`
                                                    margin-top: 20px;
                                                    padding: 10px;
                                                    border-top: 2px solid #eee;
                                                    font-size: 14px;
                                                `}
                    >
                      <p>
                        {L.s("back-to")}{" "}
                        <span
                          style={{ color: "#00bcd4", cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ uiState: UiState.LOGIN })
                          }
                        >
                          {L.s("login")}
                        </span>
                      </p>
                    </Widget.Center>
                  </Widget.MaxWidth>
                );
              }

              return (
                <Unstated.Form
                  key="reset-password"
                  init={() => ({
                    values: { email: "" },
                    editing: true
                  })}
                  submit={values => {
                    if (!values.email) {
                      return Promise.resolve({
                        values,
                        error: L.s("all-fields-required")
                      });
                    }

                    let { email } = values;
                    return appActions
                      .resetPassword({ email })
                      .then(() => setState({ resetPasswordSuccess: true }))
                      .catch(err => {
                        if (err.status === 404) {
                          return { error: L.s("email-not-found") };
                        }
                        return { error: L.s("error-reset-password-message") };
                      });
                  }}
                >
                  {({ values, errors, inputProps, editProps, submitProps }) => (
                    <Widget.MaxWidth width="400">
                      <Widget.Row extraCss="position: relative;" align="center">
                        <Icon.Email
                          color="#ccc"
                          style={{ position: "absolute", left: 10 }}
                        />
                        <Widget.Input
                          placeholder={L.s("email")}
                          {...inputProps.email}
                          extraCss="padding: 10px;padding-left: 40px;"
                        />
                      </Widget.Row>

                      <div style={{ marginTop: 20 }}>
                        {submitProps.submitError && (
                          <div
                            style={{
                              color: "red",
                              textAlign: "center",
                              marginBottom: 5
                            }}
                          >
                            {submitProps.submitError}
                          </div>
                        )}
                        {submitProps.submitting ? (
                          <Widget.Center>
                            <Widget.Spinner />
                          </Widget.Center>
                        ) : (
                          <Widget.Row justify="center">
                            <Widget.Button
                              label={L.s("cancel")}
                              style={{ height: 40, width: 300 }}
                              onClick={onClose}
                            />
                            <Widget.Button
                              label={L.s("reset-password")}
                              labelStyle={{ color: "white" }}
                              onClick={submitProps.submit}
                              disabled={
                                !submitProps.enabled || submitProps.submitting
                              }
                              backgroundColor="#00bcd4"
                              style={{ height: 40, width: 300 }}
                            />
                          </Widget.Row>
                        )}
                      </div>

                      <Widget.Center
                        extraCss={`
                                                            margin-top: 20px;
                                                            padding: 10px;
                                                            border-top: 2px solid #eee;
                                                            font-size: 14px;
                                                        `}
                      >
                        <p>
                          {L.s("back-to")}{" "}
                          <span
                            style={{ color: "#00bcd4", cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ uiState: UiState.LOGIN })
                            }
                          >
                            {L.s("login")}
                          </span>
                        </p>
                      </Widget.Center>
                    </Widget.MaxWidth>
                  )}
                </Unstated.Form>
              );
            }}
          </Unstated.WithState>
        </div>
      </Dialog>
    );
  };

  _renderValidateAccount = () => {
    let { onClose, appActions } = this.props;
    let { username, password } = this.state;

    return (
      <Dialog>
        <div style={{ padding: 20 }}>
          <h2
            style={{
              marginBottom: 10,
              textAlign: "center",
              borderBottom: "1px solid lightgrey",
              color: "#444"
            }}
          >
            VALIDATION
          </h2>

          <Unstated.WithState
            state={{
              resetValidationSuccess: false,
              submitting: false,
              errMsg: ""
            }}
          >
            {({ state, setState }) => {
              let { resetValidationSuccess, submitting, errMsg } = state;

              function _submit() {
                setState({ submitting: true });
                appActions
                  .resetValidation({ username, password })
                  .then(() =>
                    setState({
                      resetValidationSuccess: true,
                      submitting: false
                    })
                  )
                  .catch(() =>
                    setState({
                      submitting: false,
                      errMsg: L.s("err-reset-validation-message")
                    })
                  );
              }

              return (
                <Widget.MaxWidth width="400">
                  {(() => {
                    if (resetValidationSuccess === true) {
                      return (
                        <div>{L.s("reset-validation-success-message")}</div>
                      );
                    }

                    return <div>{L.s("reset-validation-message")}</div>;
                  })()}
                  <div style={{ marginTop: 20 }}>
                    {errMsg && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: 5
                        }}
                      >
                        {errMsg}
                      </div>
                    )}
                    {submitting ? (
                      <Widget.Center>
                        <Widget.Spinner />
                      </Widget.Center>
                    ) : (
                      <Widget.Row justify="center">
                        <Widget.Button
                          label={L.s("cancel")}
                          style={{ height: 40, width: 300 }}
                          onClick={onClose}
                        />
                        <Widget.Button
                          label={L.s("reset-validation")}
                          labelStyle={{ color: "white" }}
                          onClick={_submit}
                          disabled={submitting}
                          backgroundColor="#00bcd4"
                          style={{ height: 40, width: 300 }}
                        />
                      </Widget.Row>
                    )}
                  </div>

                  <Widget.Center
                    extraCss={`
                                                margin-top: 20px;
                                                padding: 10px;
                                                border-top: 2px solid #eee;
                                                font-size: 14px;
                                            `}
                  >
                    <p>
                      {L.s("back-to")}{" "}
                      <span
                        style={{ color: "#00bcd4", cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ uiState: UiState.LOGIN })
                        }
                      >
                        {L.s("login")}
                      </span>
                    </p>
                  </Widget.Center>
                </Widget.MaxWidth>
              );
            }}
          </Unstated.WithState>
        </div>
      </Dialog>
    );
  };
}

LoginPanel = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state)
  }),
  ActionCreator
)(LoginPanel);

const Login = ({ renderLogin, onClick }) => {
  if (!renderLogin) {
    return <Widget.Button label={L.s("login")} onClick={onClick} />;
  }

  return renderLogin({ onClick });
};

class LoginButton extends Component {
  render() {
    let { profile, renderLogin } = this.props;

    return (
      <Unstated.Toggle>
        {({ enable, setEnable }) => (
          <div>
            {enable && <LoginPanel onClose={() => setEnable(false)} />}
            {profile ? (
              <Widget.Button label={L.s("logout")} onClick={this._logout} />
            ) : (
              <Login
                onClick={() => setEnable(true)}
                renderLogin={renderLogin}
              />
            )}
          </div>
        )}
      </Unstated.Toggle>
    );
  }

  _logout = () => {
    let { appActions } = this.props;
    appActions.logout();
  };
}

LoginButton = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(LoginButton);

export default {
  Panel: LoginPanel,
  Button: LoginButton
};
