import React from "react";
import styled from "styled-components";
import { withPage } from "../../../src/Page";
import Login from "../../../src/Components/Login";

const Wrapper = styled.div`
  background-color: #71c6d0;
  padding-bottom: 25px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 300px;
  height: 300px;
`;

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <Logo src={"/images/pages/momcenter-logo.png"} />
        <Login.Panel />
      </Wrapper>
    );
  }
}

export default withPage(HomePage);
